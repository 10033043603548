<template>
	<div class="product">
		<div class="header">
			<div class="logo__container">
				<img class="logo" src="@/assets/biolabsciences.png" alt="Bio Lab Sciences"/>
				<div class="logo__text">REGENERATING THE BODY FOR OPTIMAL PERFORMANCE</div>
			</div>
			<div class="img__container">
				<img class="img" src="@/assets/BioLabBuilding.webp" alt="Bio Lab Building"/>
			</div>
		</div>
		<div class="content">

			<div class="col-xs-12 col-md-4 info-column">
				<div class="img"><img class="logo" src="@/assets/fluidGF2.jpg" alt="Fluid GF Logo"/></div>
				<div class="description">
					BioLab Fluid GF™ is an amniotic liquid allograft derived from the amniotic liquid within the placenta to
					advance soft tissue <strong>repair, replacement, and reconstruction.</strong>
				</div>
				<div class="quote-title">Q4206:</div>
				<div class="quote">… acute and chronic wounds and soft tissue injury, muscle and meniscus tears, ligament and
					tendon sprains, degenerative tissue disorders and Inflammatory conditions (tendonitis and fasciitis)."
				</div>
			</div>

			<div class="col-xs-12 col-md-4 info-column">
				<div class="img"><img class="logo" src="@/assets/membraneWrapLogo.png" alt="Bio Lab Membrane Wrap logo"/></div>
				<div class="description">
					BioLab Membrane Wrap™ is a dual-layered dehydrated human amnion membrane (dHAM) allograft composed primarily
					of a connective tissue matrix that helps to <strong>regenerate soft tissue while inhibiting inflammation and
					scar
					tissue formation.</strong>
				</div>
				<div class="quote-title">Q4205:</div>
				<div class="quote">… children and adults suffering from non-healing acute and chronic wounds (diabetic, venous,
					mixed, venous-arterial, pressure ulcers), complex and /or open surgical wounds and burns
				</div>
			</div>

			<div class="col-xs-12 col-md-4 info-column">
				<div class="img"><img class="logo" src="@/assets/myownskin.png" alt="Bio Lab Sciences"/></div>
				<div class="description">
					MyOwn Skin™ is a painless, non surgical procedure which leverages a small sample of a patient's own skin to
					produce up to three 100cm² skin grafts in a week.
				</div>
				<div class="quote-title">Q4226:</div>
				<div class="quote">Used to repair tissue deficits and to reduce healing time for chronic wounds and
					post-surgical wounds, with minimal to no rejection."
				</div>
			</div>
		</div>
		<div class="footer">
			CONTACT US TO LEARN MORE ABOUT ANY OF THE BIOLAB SCIENCES AVAILABLE PRODUCTS
			<div class="sub-footer">
				801-948-0804 -or- info@emsbio.com
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'PrivateProduct',
	metaInfo: {
		// if no subcomponents specify a metaInfo.title, this title will be used
		title: 'EMS Bio App Product Page',
		meta: [
			{
				name: 'description',
				content: 'Product Page for Clients and Reps to Know what products they have access to'
			},
		],
		// all titles will be injected into this template
		titleTemplate: '%s'
	},
	props: {},
	data() {
		return {};
	},
	methods: {},
	computed: {},
};
</script>

<style lang="scss" scoped>
.product {

	.footer {
		text-align: center;
		color: #262262;
		font-weight: bold;
		font-size: 1.5em;
		width: 100% ;
	}
	.content {
		display: flex;
		flex-wrap: wrap;
		height: 100%;

		.info-column {
			text-align: center;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			height: 100%;

			.quote-title {
				color: #fb7c34;
				font-weight: bold;
			}

			.logo {
				max-height: 50px;
			}

			.description, .quote, .quote-title {
				text-align: left;
			}

			.quote-title {
				margin-top: 1rem;
			}
		}
	}

	.header {
		display: flex;
		flex-wrap: wrap;

		.logo {
			&__container {
				flex: 1;
				margin-top: 2rem;
				text-align: center;
			}

			max-width: 300px;
		}

		.img {
			max-width: 100%;

			&__container {
				max-height: 250px;
				flex: 1;
				overflow: hidden;
				display: flex;
				flex-direction: column;
				justify-content: center;
				min-width: 100px;
			}
		}
	}
}
</style>
